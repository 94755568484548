<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="vx-row">
                    <!-- <h3 class="ml-2">Thông tin tài sản</h3> -->
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="branchModel" :clearable="false" :options="branchOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="branch">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Nhân sự</label>
                        <v-select class="" v-model="employeeModel" :clearable="false" :options="employeeOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="employeeCode">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('employeeCode') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Loại nghỉ</label>
                        <v-select class="" v-model="leaveTypeModel" :clearable="false" :options="leaveTypeOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="leaveType"/>
                        <span class="text-danger text-sm">{{ errors.first('leaveType') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Trạng thái</label>
                        <v-select class="" v-model="leaveStatusModel" :clearable="false" :options="leaveStatusOptions"
                                  data-vv-validate-on="blur" v-validate="'required'" name="leaveStatus"/>
                        <span class="text-danger text-sm">{{ errors.first('leaveStatus') }}</span>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Ngày bắt đầu</label>
                        <datepicker :format="'dd/MM/yyyy'" v-model="leaveInfo.fromDate" v-on:change="updateDetails"
                                    data-vv-validate-on="input" :disabled="isUpdate" v-validate="'required'"
                                    name="fromDate"></datepicker>
                        <span class="text-danger text-sm">{{ errors.first('fromDate') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Ngày kết thúc</label>
                        <datepicker :format="'dd/MM/yyyy'" v-model="leaveInfo.toDate" @change="updateDetails"
                                    data-vv-validate-on="input" :disabled="isUpdate" v-validate="'required'"
                                    name="toDate"></datepicker>
                        <span class="text-danger text-sm">{{ errors.first('toDate') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Thời gian</label>
                        <v-select class="" v-model="durationModel" :clearable="false" :options="durationOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="duration"/>
                        <span class="text-danger text-sm">{{ errors.first('duration') }}</span>
                    </div>
                </div>

                <div v-show="leaveInfo.duration == 1" class="vx-row md:mb-1 mb-4" v-for="item in leaveDetails">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Ngày nghỉ</label>
                        <datepicker :format="'dd/MM/yyyy'" v-model="item.leaveDate"
                                    data-vv-validate-on="input" v-validate="'required'" name="leaveDate"
                                    disabled></datepicker>
                        <span class="text-danger text-sm">{{ errors.first('leaveDate') }}</span>
                    </div>
                    <div class="vx-col lg:w-1/6 md:w-1/2 w-1/2 mt-2">
                        <label class="vs-input--label">Thời gian bắt đầu</label>
                        <v-select :value="item.fromHourString" :disabled="isUpdate" :clearable="false"
                                  :options="timeSpans"
                                  @input="fromHour => updateTimeSpan(item, fromHour, 'fromHour')"
                                  v-validate="'required'"/>
                    </div>
                    <div class="vx-col lg:w-1/6 md:w-1/2 w-1/2 mt-2">
                        <label class="vs-input--label">Thời gian kết thúc</label>
                        <v-select :value="item.toHourString" :disabled="isUpdate" :clearable="false"
                                  :options="timeSpans"
                                  @input="toHour => updateTimeSpan(item, toHour, 'toHour')" v-validate="'required'"/>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col md:w-1/2 w-full mt-5">
                        <vs-textarea class="w-full" label="Lí do" v-model="leaveInfo.reason" :disabled="isUpdate"
                                     v-validate="" name="reason"/>
                        <span class="text-danger text-sm">{{ errors.first('reason') }}</span>
                    </div>
                    <div class="vx-col md:w-1/2 w-full mt-5">
                        <vs-textarea class="w-full" label="Bình luận" v-model="leaveInfo.comment"
                                     v-validate="" name="comment"/>
                        <span class="text-danger text-sm">{{ errors.first('comment') }}</span>
                    </div>
                </div>

                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <vs-button class="ml-auto mt-2" @click="saveChanges"
                                       :disabled="!validateForm || !employeeOptions.length ">{{
                                    `${isUpdate ?
                                        'Cập nhật' : 'Tạo mới'}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="leaveList">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import utils from "../../../components/utils";
import moment from 'moment';

export default {
    components: {
        vSelect,
        Datepicker
    },
    data() {
        return {
            user_data: null,
            activeTab: 0,
            isUpdate: false,
            isUpdateFace: false,
            branchOptions: [],
            employeeOptions: [],
            leaveTypeOptions: [
                {value: 0, label: 'Nghỉ phép'},
                {value: 1, label: 'Nghỉ không phép'}
            ],
            leaveStatusOptions: [
                {value: 0, label: 'Chờ'},
                {value: 1, label: 'Đã duyệt'},
                {value: 2, label: 'Từ chối'},
                {value: 3, label: 'Hủy bỏ'}
            ],
            durationOptions: [
                {value: 0, label: 'Cả ngày'},
                {value: 1, label: 'Thời gian cố định'}
            ],
            leaveInfo: {},
            leaveDetails: [],
            workShiftSetting: [],
            timeSpans: [],
            file: '',
            fileName: 'Thêm dữ liệu khuôn mặt.',
            uploadPercent: 0,
            isShowPercent: false,
        }
    },
    created() {
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });
        this.leaveInfo = {
            organizationBranchId: this.$route.query.branch_id,
            employeeId: null,
            leaveType: 0,
            status: 0,
            duration: 0
        };
        this.loadEmployee()
        let leaveId = this.$route.query.id;
        if (leaveId) {
            setTimeout(() => {
                this.$vs.loading();
                this.$crm.post('/leave/find-by-id/' + leaveId).then((response) => {
                    this.$vs.loading.close();

                    this.leaveInfo = response.data;
                    this.leaveDetails = response.data.leaveDetails.map(item => {
                        item.fromHourString = utils.minuteToHour(item.fromHour)
                        item.toHourString = utils.minuteToHour(item.toHour)
                        return item;
                    })
                    this.isUpdate = true;
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            }, 0)
        }
    },
    watch: {
        'leaveInfo.fromDate': function () {
            this.updateDetails()
        },
        'leaveInfo.toDate': function () {
            this.updateDetails()
        }
    },
    computed: {
        branchModel: {
            get() {
                return {
                    label: this.branchOptions.find(x => x.value == this.leaveInfo.organizationBranchId).label,
                    value: this.leaveInfo.organizationBranchId
                }
            },
            set(obj) {
                this.leaveInfo.organizationBranchId = obj.value;
                this.loadEmployee()
            }
        },
        employeeModel: {
            get() {
                return {
                    label: this.getLabelString(this.employeeOptions.find(x => x.value == this.leaveInfo.employeeId)),
                    value: this.leaveInfo.employeeId
                }
            },
            set(obj) {
                this.leaveInfo.employeeId = obj.value;
            }
        },
        leaveTypeModel: {
            get() {
                return {
                    label: this.getLabelString(this.leaveTypeOptions.find(x => x.value == this.leaveInfo.leaveType)),
                    value: this.leaveInfo.leaveType
                }
            },
            set(obj) {
                this.leaveInfo.leaveType = obj.value;
            }
        },
        leaveStatusModel: {
            get() {
                return {
                    label: this.getLabelString(this.leaveStatusOptions.find(x => x.value == this.leaveInfo.status)),
                    value: this.leaveInfo.status
                }
            },
            set(obj) {
                this.leaveInfo.status = obj.value;
            }
        },
        durationModel: {
            get() {
                return {
                    label: this.getLabelString(this.durationOptions.find(x => x.value == this.leaveInfo.duration)),
                    value: this.leaveInfo.duration
                }
            },
            set(obj) {
                this.leaveInfo.duration = obj.value;
            }
        },
        validateForm() {
            return !this.errors.any()
        },
    },
    methods: {
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        updateTimeSpan(setting, hour, type) {
            setting[type] = hour.value;
            setting[`${type}String`] = hour.label;
        },
        updateDetails() {
            if (this.isUpdate) return;
            let details = []
            if (!this.leaveInfo.fromDate || !this.leaveInfo.toDate) return;
            let fromDateObj = moment(this.leaveInfo.fromDate);
            let toDateObj = moment(this.leaveInfo.toDate);
            while (fromDateObj.format('YYYY-MM-DD') <= toDateObj.format('YYYY-MM-DD')) {
                details.push({
                    leaveDate: fromDateObj.format("YYYY-MM-DD"),
                    fromHour: 0,
                    toHour: 0,
                    fromHourString: "00:00",
                    toHourString: "00:00",
                })
                fromDateObj.add(1, 'd');
            }
            this.leaveDetails = details
        },
        loadEmployee() {
            if (!this.leaveInfo.organizationBranchId) return;

            this.$vs.loading();
            this.$crm.post('/employee/find-by-organization-branch/' + this.leaveInfo.organizationBranchId).then((response) => {
                this.employeeData = response.data;
                this.$vs.loading.close();
                if (response.data.length == 0) {
                    this.employeeOptions = []
                    return this.$vs.notify({
                        title: 'Error',
                        text: 'Không tồn tại nhân viên nào',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                }
                this.employeeOptions = response.data.map(item => {
                    return {
                        value: item.employeeId,
                        label: item.employeeCode + " " + item.fullName
                    }
                });
                this.employeeModel = this.employeeOptions[0]
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;
                let url = this.isUpdate ? `/leave/update/${this.leaveInfo.id}` : `/leave/assign/${this.leaveInfo.organizationBranchId}/${this.leaveInfo.employeeId}`,
                    data = this.leaveInfo;
                data.leaveDetails = this.leaveDetails
                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.loading.close();
                    this.leaveList();
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} đơn xin nghỉ thành công`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    // this.$router.push(`/user/manage-leave${this.leaveInfo.organizationBranchId ? `?branch_id=${this.leaveInfo.organizationBranchId}` : ''}`).catch(() => {
                    // })
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        leaveList() {
            this.$router.push(`/user/manage-leave${this.leaveInfo.organizationBranchId ? `?branch_id=${this.leaveInfo.organizationBranchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>

<style scoped>

</style>
